<template>
  <div>
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 12" :key="n" cols="4">
       <v-sheet class="mt-2 px-3">
        <v-skeleton-loader class="mx-auto" type="image" height="75"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <div v-else class="px-0 mt-3">
      <v-card flat tile>
        <template v-for="(item, index) in tilesList">
          <v-row class="mx-0 px-2" :class="item.list.length > 3 ? 'custom_border_color_style' : ''" :key="`${item.id}_${index}_items`">
            <template v-for="(navItem, index) in item.list">
              <v-col cols="4" class="pa-0" :class="`${setBorderRight(index + 1, item.list)} ${setBorderBottom(index + 1, item.list)}`" :key="`${navItem.to}_module_${index}`">
                <v-card tile flat :to="navItem.to" @click="navItem.isCustomModules ? openInNewTab(navItem) : navItem.name === $t('message.mixed.scanQRCode') ? scanning() : ''" class="pa-0">
                  <div class="text-center pt-1">
                    <v-avatar>
                      <v-icon v-if="navItem.icon !== 'mdi-qrcode-scan'" color="grey darken-3" size="33">{{ navItem.icon ? navItem.icon : 'mdi-cube'}}</v-icon>
                      <svg v-else  style="width: 30px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="scanner" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                      <path fill="#424242" d="M368 64H96c-53 0-96 43-96 96 0 52.4 42.1 94.9 94.3 95.8L6.4 408C-6.9 431 1 460.3 24 473.6l55.4 32c23 13.3 52.3 5.4 65.6-17.6l133.9-232H368c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16zM256 448c17.7 0 32-14.3 32-32V304.3L205 448h51zm376 0H456c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-96H456c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm0-288H456c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8zm0-64H456c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 288H456c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm0-128H456c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path></svg>
                    </v-avatar>
                  </div>
                  <v-card-text class="text-center pa-2 font-weight-medium">{{$t(navItem.name)}}</v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </template>
      </v-card>
    </div>
    <!-- SCAN BAR CODE -->
    <v-dialog v-model="scanDialog" persistent max-width="500px" class="mt-0">
      <v-card>
        <v-card-title>
          {{$t('message.common.scan')}}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1">
          {{ scanError }}
            <div>
              <video id="video" style="border: 1px solid gray; width:100%; height:auto;" ref="video"></video>
            </div>
            <div id="sourceSelectPanel" v-if="videoInputDevices.length > 1">
              <v-select id="sourceSelect" v-model="selectedDeviceId" outlined :items="videoInputDevices" hide-details item-text="label" item-value="deviceId" :label="$t('message.common.changeVideoSource')"></v-select>
            </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" id="scanClose" @click="stopScanningSession">{{ $t('message.common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showProjectSelectDialog" persistent max-width="500px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('message.projects.selectProject') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1">
          <v-sheet v-if="isProjectsByUsedProductLoading" class="d-flex justify-center pa-5">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-sheet>
          <v-autocomplete
            v-else
            v-model="seletedProject"
            :items="selectableProjectForPickup"
            :item-text="item => `${item.po_ranked ? item.po_ranked : ''} ${item.project_name ? ' - ' + item.project_name : ''} ${item.ao_ranked ? ' - ' +  item.ao_ranked : ''} ${item.work_order_name ? ' - ' + item.work_order_name : ''}`"
            item-value="timelogapp_id"
            :label="$t('message.projects.selectProject')"
            @input="gotoPickup(seletedProject)"
            menu-props="auto, overflowY"
            outlined
            dense
          >
          </v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="handleProjectSelection">{{ $t('message.common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { BrowserMultiFormatReader } from '@zxing/library'
import PermissionsMixin from '@/mixins/permissions'
import FeaturesMixin from '@/mixins/features_list'
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'

export default {
  mixins: [PermissionsMixin, FeaturesMixin],
  data () {
    return {
      allTiles: {
        stemp: { icon: 'mdi-timer', to: '/stemp', name: 'message.layout.stemp' },
        deviations: { icon: 'mdi-comment-alert-outline', to: '/deviations', name: 'message.layout.deviation' },
        projects: { icon: 'mdi-server', to: '/projects', name: 'message.layout.projects' },
        calendar: { icon: 'mdi-calendar', to: '/calendar', name: 'message.layout.calendar' },
        files: { icon: 'mdi-folder-multiple', to: '/files', name: 'message.layout.files' },
        checklists: { icon: 'mdi-book-plus', to: '/global_checklist', name: 'message.layout.checklists' },
        equipments: { icon: 'mdi-developer-board', to: '/equipments', name: 'message.layout.equipments' },
        chemicallist: { icon: 'mdi-flask', to: '/chemicallist', name: 'message.layout.chemicallist' },
        warehouse: { icon: 'mdi-database', to: '/warehouse', name: 'message.layout.warehouse' }
      },
      stempItem: { _id: 'static_item1', icon: 'mdi-timer', to: '/stemp', isStatic: true, name: 'message.layout.stemp' },
      deviationItem: { _id: 'static_item2', icon: 'mdi-leak', to: '/deviations', isStatic: true, name: 'message.layout.deviation' },
      projectItem: { _id: 'static_item3', icon: 'mdi-note-multiple-outline', to: '/projects', isStatic: true, name: 'message.layout.projects' },
      calendarItem: { _id: 'static_item4', icon: 'mdi-calendar', to: '/calendar', isStatic: true, name: 'message.layout.calendar' },
      fileItem: { _id: 'static_item5', icon: 'mdi-file', to: '/files', isStatic: true, name: 'message.layout.files' },
      checklistItem: { _id: 'static_item6', icon: 'mdi-book-plus', to: '/global_checklist', isStatic: true, name: 'message.layout.checklists' },
      equipmentItem: { _id: 'static_item7', icon: 'mdi-developer-board', to: '/equipments', isStatic: true, name: 'message.layout.equipments' },
      warehouseItem: { _id: 'static_item8', icon: 'mdi-warehouse', to: '/warehouse', isStatic: true, name: 'message.layout.warehouse' },
      fieldsLoading: false,
      reloadApp: false,
      searchTerm: '',
      scanDialog: false,
      scanError: '',
      codeReader: {},
      videoInputDevices: [],
      selectedDeviceId: window.localStorage.getItem('WelditPwa_storeCamera') ? window.localStorage.getItem('WelditPwa_storeCamera') : 0,
      showProjectSelectDialog: false,
      selectableProjectForPickup: [],
      isProjectsByUsedProductLoading: false,
      selectedProductCode: null,
      seletedProject: null
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getNewContentAvailable', 'timerData', 'getuserDetails', 'getHostRefApi', 'userId']),
    commonList () {
      const navItems = []
      if (!this.$store.state.common.isStempExcluded) navItems.push(this.stempItem)
      // if (!this.$store.state.common.isStempExcluded) navItems.push(this.stempsItem)
      if (!this.$store.state.common.isDeviationExcluded) navItems.push(this.deviationItem)
      if (!this.$store.state.common.isProjectExcluded) navItems.push(this.projectItem)
      navItems.push(this.calendarItem)
      navItems.push(this.fileItem)
      if (this.$store.state.common.includeGlobalChecklist) navItems.push(this.checklistItem)
      if (!this.$store.state.common.isEquipmentExcluded) {
        if (this.checkActionPermission(this.CONSTANTS.equipment, this.CONSTANTS.LIST)) navItems.push(this.equipmentItem)
      }
      if (!this.$store.state.common.isWarehouseExcluded) {
        if (this.checkFeatureEnabled('warehouse') && this.checkActionPermission(this.CONSTANTS.warehouse, this.CONSTANTS.LIST)) navItems.push(this.warehouseItem)
      }
      return [
        { id: 'navigationItems', list: navItems }
      ]
    },
    tilesList () {
      let navItems = []
      Object.keys(this.allTiles).forEach(key => {
        var checklistFiles = ''
        if (key === 'checklist_files') {
          key = 'checklists'
          checklistFiles = 'checklist_files'
        } else checklistFiles = ''
        const featureObj = this.getFeatureObj(key)
        if (featureObj && featureObj.enabled && this.checkActionPermission(this.CONSTANTS[key], this.CONSTANTS.LIST)) {
          let translatedLabel = featureObj.label
          switch (featureObj.label) {
            case 'Stemp':
              translatedLabel = this.$t('message.layout.stemp')
              break
            case 'Deviations':
              translatedLabel = this.$t('message.layout.deviation')
              break
            case 'Projects':
              translatedLabel = this.$t('message.layout.projects')
              break
            case 'Calendar':
              translatedLabel = this.$t('message.layout.calendar')
              break
            case 'Checklists':
              translatedLabel = this.$t('message.layout.checklists')
              break
            case 'Files':
              translatedLabel = this.$t('message.layout.files')
              break
            case 'Chemical List':
              translatedLabel = this.$t('message.layout.chemicallist')
              break
            case 'Kjemisk liste':
              translatedLabel = this.$t('message.layout.chemicallist')
              break
            case 'Cheminių medžiagų sąrašas':
              translatedLabel = this.$t('message.layout.chemicallist')
              break
            case 'Lista substancji chemicznych':
              translatedLabel = this.$t('message.layout.chemicallist')
              break
            case 'Equipments':
              translatedLabel = this.$t('message.layout.equipments')
              break
            case 'Warehouse':
              translatedLabel = this.$t('message.layout.warehouse')
              break
            default:
          }
          key = checklistFiles === 'checklist_files' ? 'checklist_files' : key
          this.allTiles[key].name = checklistFiles === 'checklist_files' ? 'Checklist files' : translatedLabel
          navItems.push(this.allTiles[key])
        }
      })
      var qrCodes = [{ icon: 'mdi-qrcode-scan', name: this.$t('message.mixed.scanQRCode'), to: '/irscanner' }]
      navItems = [...navItems, ...qrCodes]
      // get custom modules and add with navItems
      const customModules = this.getCustomModules()
      if (customModules) navItems = [...navItems, ...customModules]
      return [
        { id: 'navigationItems', list: navItems }
      ]
    }
  },
  async beforeRouteEnter (to, from, next) {
    await next(vm => {
      if (from && from.path === '/login') vm.reloadApp = true
    })
  },
  created () {
    this.fieldsLoading = false
    this.$store.dispatch('getGeneralSettings')
    if (/(http:\/\/|https:\/\/).+\..+\..+/.test(this.getHostRefApi)) { // check this.getHostRefApi variable is a correct URL
      this.updateFeaturesData()
    }
  },
  methods: {
    openInNewTab (cModule) {
      if (cModule.link) window.open(cModule.link, '_blank')
    },
    setBorderRight (itemPosition, list) {
      // const isNotLastItem = itemPosition < list.length
      return (itemPosition % 3) !== 0 ? 'grid-border-right' : '' // && isNotLastItem add after !== 0
    },
    setBorderBottom (itemPosition, list) {
      const noOf3s = Math.floor(list.length / 3)
      const remainingNos = list.length % 3
      if (remainingNos) {
        return itemPosition <= (noOf3s * 3) ? 'grid-border-bottom' : ''
      } else {
        let nextRow = [0, 1].includes(remainingNos) ? 3 : 1
        if (remainingNos === 2) nextRow = 2
        return (itemPosition + nextRow) <= (noOf3s * 3) ? 'grid-border-bottom' : ''
      }
    },
    scanning (module) {
      const self = this
      this.scanDialog = false
      self.scanError = ''
      self.scanDialog = false
      // const selected = window.localStorage.getItem('WelditPwa_storeCamera')
      self.codeReader = new BrowserMultiFormatReader()
      self.codeReader.getVideoInputDevices().then((videoInputDevices) => {
        self.videoInputDevices = videoInputDevices
        /* if (selected && selected !== 'undefined' && selected !== null) {
          self.selectedDeviceId = selected
        } else { */
        self.selectedDeviceId = this.$vuetify.breakpoint.mobile && videoInputDevices.length > 1 ? videoInputDevices[1].deviceId : videoInputDevices[0].deviceId
        // }
        this.scanFromVideoSource(self.selectedDeviceId, module)
      }).catch((err) => {
        console.log(err)
      })
    },
    scanFromVideoSource (videoDeviceId, module) {
      if (videoDeviceId) window.localStorage.setItem('WelditPwa_storeCamera', videoDeviceId)
      this.codeReader.decodeOnceFromVideoDevice(videoDeviceId, 'video')
        .then((result) => {
          this.searchTerm = result.text
          this.stopScanningSession()
          setTimeout(() => {
            this.getRespectiveValue()
          }, 50)
        }).catch((err) => {
          if (err && err.name && err.name === 'NotAllowedError') alert(this.$t('message.common.permissionDenied'))
        })
    },
    stopScanningSession () {
      this.scanDialog = false
      this.codeReader.reset()
      this.codeReader.stopContinuousDecode()
      this.codeReader = {}
    },
    getRespectiveValue () {
      const result = {}
      var qr = ''
      if (this.searchTerm.match(/o=.*m=/) || this.searchTerm.match(/oid=.mid=/)) {
        this.searchTerm.split('?')[1].split('&').map(x => {
          result[x.split('=')[0]] = x.split('=')[1]
        })
      }
      const barcode = this.searchTerm.toString().split('?')[0].split('/')[1]
      const formData = new FormData()
      const data = Object.keys(result)
      data.forEach(key => {
        formData.append(key, result[key])
      })
      hostAppApi.post(`${this.getHostRefApi}qrcode/${barcode}`, formData)
        .then((response) => {
          if (response.data.status === 'success') {
            try {
              if (result !== {} && result.is_pr && result.tid) {
                if (result.mid === 'deviation') result.mid = 'deviations'
                else if (result.mid === 'project-checklist') result.mid = 'checklists'
                this.$router.push(`/projects/${result.tid}/${result.mid}`)
              } else if (result.mid === 'equipment') {
                this.$router.push(`/equipments/${result.oid}`)
              } else if (result.mid === 'product') {
                qr = this.searchTerm.split('?')[0].split('/')
                this.$router.push(`/product_view?qr=${qr[qr.length - 1].replace('&', '-')}`)
              } else if (result.i && result.t) {
                if (result.m === 'deviation') result.m = 'deviations'
                else if (result.m === 'project-checklist') result.m = 'checklists'
                if (result.c) this.$router.push(`/projects/${result.t}/custom_modules/${result.c}?name=${result.m}`)
                else this.$router.push(`/projects/${result.t}/${result.m}`)
              } else if (result.m === 'equipment') {
                this.$router.push(`/equipments/${result.o}`)
              } else if (result.m === 'chemicallist') {
                this.$router.push(`/chemicallist/${result.o}`)
              } else if (result.m === 'product' || result.m === 'warehouseorder') {
                this.selectedProductCode = this.searchTerm.split('?')[0].slice(1)
                this.showProjectSelectDialog = true
                this.getProjectsByUsedProduct()
              } else if (result.m) {
                qr = this.searchTerm.split('?')[0].split('/')
                this.$router.push(`/custom_modules?qr=${qr[qr.length - 1].replace('&', '-')}`)
              } else {
                this.$router.push('/not_found')
              }
            } catch (e) {
              this.$router.push('/not_found')
            }
          }
        })
        .catch((e) => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.noResults' })
          this.$router.push('/not_found')
        })
    },
    updateFeaturesData () {
      this.$i18n.locale = window.localStorage.getItem(process.env.VUE_APP_LOCALE_STORAGE) ? window.localStorage.getItem(process.env.VUE_APP_LOCALE_STORAGE) : 'no'
      const authToken = this.$cookie.get(process.env.VUE_APP_TOKEN)
      if (authToken) {
        hostAppApi.get(`${this.getHostRefApi}check_and_get_tenant_features?user_id=${this.userId}`, { headers: { language: this.$i18n.locale } })
          .then((response) => {
            if (response && response.data) {
              if (response.data.features) {
                const features = response.data.features
                this.$root.$emit('stempTimerActive', features)
                if (features) this.features = features
              }
            }
          })
      }
    },
    getProjectsByUsedProduct () {
      this.isProjectsByUsedProductLoading = true
      hostAppApi.get(`${this.getHostRefApi}get_active_projects?is_pwa_warehouse=1`)
        .then((response) => {
          this.selectableProjectForPickup = response.data
        })
        .catch()
        .finally(() => { this.isProjectsByUsedProductLoading = false })
    },
    gotoPickup (id) {
      this.showProjectSelectDialog = false
      this.$router.push(`projects/${id}/materials/pick/${this.selectedProductCode}`)
    },
    handleProjectSelection () {
      this.showProjectSelectDialog = false
      this.$router.push(`/product_view?qr=${this.selectedProductCode.replace('&', '-')}`)
    }
  },
  watch: {
    reloadApp (val) {
      if (val) this.$router.go()
    },
    selectedDeviceId (val) {
      if (val) {
        window.localStorage.setItem('WelditPwa_storeCamera', val)
        this.scanFromVideoSource(val, this.module)
      }
    },
    '$i18n.locale' (val) {
      if (val) this.updateFeaturesData()
    }
  }
}
</script>
<style>
.dashboard_bg_color {
  /* background: #578ebe; */
  background: linear-gradient(180deg, grey 85%, #ffffff 15%);
  border-radius: 0 !important;
}
.grid-border-bottom {
  border-bottom: 0.85px solid #19191912 !important;
}
.grid-border-right {
  border-right: 0.85px solid #19191912 !important;
}
.custom-margin {
  margin-top: 28%;
}
.custom_bg_color {
  background: grey !important;
}
.custom_border_color_style {
  /* border: 15px solid #578ebe; */
  border-bottom: none;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  min-height: 30vh !important;
}
</style>
